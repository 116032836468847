@import "../../../../styles/_variables.scss";

.header-search {
  background: #f5f5f5;
  max-width: 100%;
  width: 0%;
  border: 0px;
  position: relative;

  &::-webkit-input-placeholder {
    font-size: $fontSizeXS;
    color: #969696;
  }
  &::-moz-placeholder {
    font-size: $fontSizeSM;
    color: #969696;
  }
  &::placeholder {
    font-size: $fontSizeXS;
    color: #969696;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #969696;
  }
  &:focus {
    background: #f5f5f5;
    border: 0;
    outline: none;
    padding: 8px 12px;
    padding-right: 22px;
  }
}

.headerExpanded {
  background: #eef0f2;
  border: 0;
  outline: none;
  border-radius: 4px;
  width: 360px;
  padding: 8px 12px !important;
  padding-right: 22px;
}

.searchBar {
  justify-content: flex-end;
}
.header-search-icon {
  color: $colorDark;
  position: absolute;
  vertical-align: top;
  display: inline-block;
  top: 10px;
  border-radius: 4px;
  padding: 6px;
  &:hover {
    background: #eef0f2;
  }
  img {
    height: 13px;
    width: 13px;
  }
  cursor: pointer;
  right: 10px;
  color: $colorDark;
  &:focus {
    background-color: red;
    border: solid 2px black;
  }
}
.header {
  .header-search-icon {
    &.search-open {
      svg {
        path {
          fill: $colorDark;
        }
      }
    }
  }
  &.payment-header {
    box-shadow: 0px 12px 24px rgba(10, 10, 10, 0.04);
  }
}

.navbar {
  padding: 0;
}
.navbar-expand-md .navbar-nav .dropdown-menu {
  outline: none;
  border: 0;
  margin: 0;
}
.header {
  padding: 5px 0 15px 0;
  .nav-link {
    color: $colorDark;

    &:hover,
    &:active,
    &:focus {
      // color:$colorPrimary;
      background: $gray200;
      border-radius: $borderRadiusSize;
      // transition: $transition;
      // background-color: $hoverBgr;
      .fa-angle-down,
      .fa-angle-down:before {
        color: $colorDark;
      }
      svg {
        path {
          fill: $colorDark;
        }
      }
    }
    &.empty-cart:hover {
      background: none;
      cursor: default;
      color: inherit;
      svg {
        path {
          fill: $colorDark;
        }
      }
    }
  }
  .fa-angle-down {
    font-size: 18px;
    top: 2px;
    position: relative;
    color: #707070;
  }
}

/****/

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1);
  font-size: $fontSizeSM;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1);
  font-size: $fontSizeSM;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1);
  font-size: $fontSizeSM;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1);
  font-size: $fontSizeSM;
}
.country-dropdown {
  width: 221px;
}
.selected-country {
  color: $colorPrimary;
  &:before {
    font-family: "FontAwesome";
    content: "\f00c ";
    display: inline-block;
    padding-right: 3px;
    vertical-align: middle;
    font-weight: 300;
    position: absolute;
    left: -8px;
  }
}

.categories-left {
  //   margin-top: 7px !important;
  // background: #FCFCFC;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 0;
  text-transform: none;
  //   top:10px;
}

.user:before {
  left: 70%;
}

@media (max-width: 991px) {
  .categories1:before {
    left: 14%;
  }
}
@media (max-width: 991px) {
  .category {
    .dropdown-menu-right {
      right: auto;
      left: -3rem;
    }
  }
  .user-pro {
    .dropdown-menu-right {
      right: auto;
      left: -3px !important;
    }
  }
}

.user-pro {
  .loggedOut {
    // right: auto;
    // left: -10px !important;
    color: #333333 !important;
  }
  .loggedIn {
    // right: 10px;
    // left: auto !important;
    .dropdown-item {
      color: $colorDark !important;
    }
  }
  .dropdown-item {
    font-weight: normal;
    padding-top: 7px;
    padding-bottom: 7px;
  }
}

.categories1 {
  box-shadow: 0px 8px 13px 0px rgba(0, 0, 0, 0.2);
  .item {
    color: #707070 !important;
    font-weight: 500;
    font-size: 14px !important;
    padding: 15px 10px;
    i {
      display: none;
    }
    &.active {
      color: #707070 !important;
      font-size: 14px;
      background-color: #f7f7f7;
      border-radius: 0;
      i {
        display: inline-block;
        color: $colorPrimary;
      }
    }
  }
}

.category {
  .dropdown:hover > .dropdown-menu {
    display: block;
  }

  .dropdown:hover > a {
    background: $gray200;
    border-radius: 4px;
  }

  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link {
    border: none;
  }
  .nav-tabs .nav-link:focus,
  .nav-tabs .nav-link:hover {
    border: none;
  }
  .nav-tabs .nav-item.show .nav-link,
  .nav-tabs .nav-link.active {
    border: none;
  }
  .nav-tabs {
    border: none;
  }
}

.user {
  font-size: 13px;
  color: #000000;
  .login {
    font-weight: 500;
  }
  .dropdown-item {
    font-size: 14px;
    color: $colorDark;
  }
  .dropdown-item.active,
  .dropdown-item:active {
    text-decoration: none;
    background-color: transparent;
    color: #000000;
    outline: none;
  }
}

.exp-list {
  font-size: $fontSizeXS;
  line-height: 24px;
  font-family: $fontFamily;
  padding-top: 6px;
  padding-top: 10px;
  color: $colorLightgreyish;
  a {
    color: $colorDark;
    line-height: 20px;
    &:hover {
      // color: $colorPrimary;
      background: $gray200;
      border-radius: $borderRadiusSize;
      transition: $transition;
    }
  }
}

.nav-voucher-list {
  ul {
    li {
      text-align: left;
      display: block;
    }
  }
}
.nav-list-container {
  padding: 25px 54px;
  .nav-voucher-list:first-child {
    ul {
      // padding-left: 52px;
      // margin-bottom: 24px;
    }
  }
}

.left-align {
  padding-left: 12px;
  fallback: unset;
  grid: 0em;
}

.cart-count {
  position: absolute;
  top: -8px;
  font-size: 10px;
  background: $colorPrimary;
  width: 20px;
  height: 20px;
  color: #fff;
  border-radius: 50%;
  text-align: center;
  right: -9px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 991px) {
  .cart-count {
    color: #fff;
    background: $colorPrimary;
    top: -4px;
    transform: none;
    width: 16px;
    height: 16px;
    // right: -10px;
    left: 15px;
    line-height: 15px;
  }
}

.point-icon {
  background: #f3983e;
  padding: 3px;
  border-radius: 50%;
  font-size: 11px;
  vertical-align: middle;
  color: #fff;
}

.phone-icon {
  height: 19px;
  vertical-align: baseline;
}
.scroll {
  max-width: 419px;
  overflow: auto;
  max-height: 276px;
}
.dropdown-min-width {
  width: 623px;
}

.light-odd-background {
  background-color: #f7f7f7;
  max-width: 12.65rem;
}
.scroll_ {
  overflow: scroll;
  max-height: 100vh;
}

.cart {
  color: $colorDark;
}

/********* Header v2 *******/

.countryList {
  span {
    color: #24292e;
  }
}

.usr-points {
  font-weight: normal;
  @media (max-width: 991px) {
    width: 50%;
  }
}

.country-search {
  background: #f6f8fa;
  width: 100%;
  border: none;
  // box-shadow: 0px 3px 6px #00000029 !important;
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 3px 10px;
  height: 30px;
  min-height: 30px;
  border-radius: 4px;
  &::-moz-placeholder {
    font-size: $fontSizeSM;
  }
  &::placeholder {
    font-size: $fontSizeXS;
  }
  &:focus {
    border: 0;
    outline: none;
    background: #f7f7f7;
  }
}

.search-icon-position {
  position: absolute;
  padding-top: 9px;
  right: 25px;
  @media (max-width: 991px) {
    right: 0px;
  }
}

.countrySearchResultList {
  max-height: 400px;
  overflow: auto;
  padding: 0rem 0.5rem 0 1.5rem;
  display: block;
}

.fixed-header {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: white;
  border-bottom: 1px solid #f2f3f2;
  margin-bottom: 32px;
  transition-property: all; 
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;

  &.down {
    top: -38px;
  }

  &.up {
    top: 0px;
  }

  .dropdown {
    display: none;
    position: absolute;
    left: 0;
    background-color: #fff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1),
      0px 2px 4px -1px rgba(0, 0, 0, 0.06), 0px 4px 6px -1px rgba(0, 0, 0, 0.1);
    padding: 12px 0px;
    z-index: 5;
    border-radius: 4px;
    .countryPills {
      max-height: 400px;
      overflow-y: auto;
      a{
        max-width: 350px;
        &:hover {
        color: unset;
        }
      }
      .countryPill {
        padding: 12px;
        display: flex;
        align-items: center;
        column-gap: 8px;
        &:hover {
          background-color: #f5f7f9;
        }
      }
    }
    &.rewardCategories {
      .countryPills {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
      }
    }
  }
  .menuSelect {
    display: inline-block;
    position: relative;
    .activeMenu {
      height: 1px;
      background-color: #d72d5a;
      position: absolute;
      width: 100%;
      bottom: 0px;
      z-index: 1;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
    &:hover {
      .dropdown {
        display: block;
      }
    }
  }
}

.headerActions {
  padding: 16px 0px;
  height: 74px;
  border-bottom: 1px solid #e7e9eb;
  .logoContainer {
    width: 105px;
    height: 42px;
    margin-right: 64px;
    img {
      width: 100%;
      height: 100%;
      max-height: 42px;
      max-width: 105px;
      object-fit: contain;
    }
  }
}

.header-actions-items {
  column-gap: 8px;
}

.headerNavigation {
  height: 54px;
  .leftSection {
    color: #d72d5a;
    padding-left: 5px;
  }
  .rightSection {
    column-gap: 8px;
  }
}

.header-navigation {
  width: 100vw;
  background-color: #fff;
  margin: -17px 0 8px -20px;
  padding: 10px 20px;
  top: 64px;
  z-index: 999;
  position: sticky;
  @media screen and (min-width: 576px) {
    margin-left: calc(((100vw - 500px) / 2) * -1);
  }
  @media screen and (min-width: 768px) {
    margin-left: calc(((100vw - 690px) / 2) * -1);
  }
}

.redeem-tooltip {
  .tooltip {
    top: 10px !important;
    &-inner {
      text-align: left !important;
    }
  }
}

.languageDropdown
{
  left: -150px !important;
}