.home-grid {
  .home-grid-sub-title {
    // width: 85%;
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 36px;
  }
}

.grecaptcha-badge {
  z-index: 99999;
}
.react-cookie-banner {
  position: fixed;
  width: 88%;
  bottom: 20px;
  background: #f6f8fa;
  color: #6a737d;
  border: 2px solid #d1d5da;
  box-sizing: border-box;
  // box-shadow: 0px 0px 50px rgb(0 0 0 / 10%);
  border-radius: 8px;
  z-index: 999999;
  margin-left: 6%;
  margin-right: 6%;
  padding: 15px 2%;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  h3 {
    font-size: 18px;
  }
  .msg-wrap {
    max-width: 90%;
  }
  @media screen and (max-width: 991px) {
    width: 92%;
    margin-left: 4%;
    margin-right: 4%;
  }
  // @media screen and (min-width: 992px) and (max-width: 1200px) {
  //     width: 70%;
  //     margin-left: 4%;
  //     margin-right: 4%;
  // }

  .cookie-btn-wrap {
    display: inline-block;
    margin-left: 3%;
    @media screen and (max-width: 991px) {
      margin: 10px 0 0 0;
      display: block;
      .btn {
        width: 100%;
      }
    }
  }
}
.cookie-link {
  text-decoration: underline;
}

#faq-bot-button {
  display: none;
}
.entityCard.voucherCard .entityCardImgWrapper .imgWrapperInner {
  background-position: 0 0 !important;
}

.topSubHeader {
  position: relative;
  border-bottom: 6px solid $colorPrimary;
  background-color: transparent;
}

#otp_modal {
  .modal-header {
    text-align: center;
    border: 0;
    padding-top: 30px;
    .modal-title {
      margin-bottom: 0;
      line-height: 1.5;
      width: 100%;
      padding-right: 8px;
      font-size: 20px;
      font-weight: 500;
      color: #000;
      .float-right {
        float: right;
      }
    }
    .modal-body .btn.btn-link {
      color: #36849e !important;
    }
  }
}

.nav-divider {
  border: 0;
  border-bottom: 1px solid #e6e6e6;
  margin: 10px 10px;
}

.navbar .nav-item .usr-points {
  // display: inline-flex !important;
  align-items: center;
  margin: 3px 4px 2px;
}

.editEmail {
  position: relative;
  top: -34px;
  left: 124px;
  right: auto;
  @media screen and (max-width: 991px) {
    left: 95px;
  }
  @media screen and (min-width: 991px) and (max-width: 991px) {
    left: 234px;
  }
}

.header .searchsuggest #search-results {
  color: $colorDark !important;
}

.Home_Modal {
  a.nav-link {
    font-size: 14px;
    color: #6a737d !important;
    padding: 8px;
    text-transform: unset !important;
  }
  .nav-link.active {
    border-bottom: 2px solid $colorPrimary !important;
  }
  .person-cart-img {
    position: relative;
    height: 200px;
    img {
      position: absolute;
      height: 100%;
      right: -5px;
      top: -150px;
    }
  }
}

.help_modal {
  border-bottom-right-radius: 10px;
  background-color: #36849e;
  width: 2rem;
  height: 3rem;
  border-top-right-radius: 10px;
  text-align: center;
  position: fixed;
  left: 0;
  top: 40%;
  z-index: 999;
  cursor: pointer;
  img {
    margin-top: 0.9rem;
  }
}

.Home_Modal {
  @media (min-width: 992px) {
    min-width: 796px !important;
  }
  .welcome {
    @media (max-width: 992px) {
      width: 100%;
    }
  }
}

// .validityModal .modal-content{
//   width: 100%;
//   height: 100%;
//   margin: 0;
//   top: 0;
//   left: 0;
// }

// .modal {
//   padding: 0 !important; // override inline padding-right added from js
// }
.modal {
  .validityModal.modal-dialog {
    width: 100%;
    max-width: none;
    height: 100%;
    margin: 0;
    .modal-body {
      overflow-y: auto;
      margin: auto;
      width: 48%;
      padding-top: 3%;
    }
    .modal-content {
      // height: 100%;
      border: 0;
      border-radius: 0;
      .btn {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      .form-control:focus {
        border-color: $colorSecondary !important;
      }
      .link {
        color: $colorSecondary;
      }
      .xoxo-code-block {
        border: 1px dashed #707070;
        margin: auto;
        a.homelink:hover {
          color: #36849e;
        }
      }
    }
  }
}
.cat-banner {
  max-height: 200px;
  margin-bottom: 30px !important;
  margin-top: 15px !important;
  img {
    max-height: 200px;
    max-width: 100%;
  }
}
@media (max-width: 991px) {
  .cat-banner {
    margin-bottom: 30px !important;
    margin-top: 5px !important;
    img {
      // max-height: 120px;
      // min-height: 100px;
    }
  }
}
.error {
  color: #f21c1c;
}
.pointer {
  cursor: pointer;
}
.filter-black {
  filter: brightness(0%);
}
.isd-code {
  .react-select {
    border: 0;
    outline: none;
    .css-yk16xz-control {
      border: 0;
      border-bottom: 1px solid #d6d6d6;
      &:active,
      &:focus {
        border: 0;
        border-bottom: 1px solid #d6d6d6;
        outline: none;
      }
    }
    .disabled {
      background-color: #e9ecef !important;
      background-image: none !important;
    }
  }
}

.isd-codex.disabled {
  .react-select {
    border: 0;
    outline: none;
    .css-yk16xz-control {
      border: 0;
      border-bottom: 1px solid #d6d6d6;
      &:active,
      &:focus {
        border: 0;
        border-bottom: 1px solid #d6d6d6;
        outline: none;
        background-color: #e9ecef !important;
        background-image: none !important;
      }
    }
  }
}

.pagination {
  display: flex;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}
.pagination > li {
  display: inline;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 6px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
}
.pagination li a.active {
  background: red;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  // background-color: $colorPrimary;
  // border-color: $colorPrimary;
  color: $colorPrimary;
  border-radius: 6px;
}

.pagination > li > a,
.pagination > li > span {
  color: #777;
  font-weight: 600;
  font-size: 16px;
}
.pagination-arrow {
  // border: 1px solid #E0E0E0;
  // padding: 7px 6px;
  display: flex;
  align-items: baseline;
  border-radius: 3px;
  color: #000;
  font-weight: 600;
  vertical-align: middle;
}
.primary-color {
  color: $colorPrimary;
}

.sorting {
  display: inline-block;
  .btn {
    border: 1px solid #dddddd;
  }
}
.sort-by {
  border-radius: 4px;
  box-shadow: none;
}

.rotate-45 {
  transform: rotate(45deg);
}
.card-glass-primary {
  @include cardGradient($gradientprimarydark, $gradientprimarylight);
  border: none;
}

.w-auto {
  .DayPicker_transitionContainer,
  .CalendarMonth.CalendarMonth_1,
  .DayPicker__horizontal {
    width: 100% !important;
    padding: 0;
  }

  .DayPicker__withBorder {
    width: 100% !important;
  }
  .DayPicker_transitionContainer__horizontal {
    width: 100% !important;
  }
  .CalendarMonthGrid__horizontal {
    width: auto;
  }
  .DayPicker_weekHeaders__horizontal {
    margin: 0 !important;
  }
  .DayPicker_weekHeader_li {
    width: 31px !important;
  }
  .CalendarMonthGrid.CalendarMonthGrid_1,
  .DayPicker.DayPicker_1 > div > div {
    width: auto !important;
  }
  .CalendarMonth_caption strong {
    font-size: 14px;
  }
}

///payment
.order-details-complete {
  margin: 30px 15px;
  // border: 1px solid #ccc;
  position: relative;

  .confirm-seal-image {
    width: 13% !important;
    margin-bottom: 20px !important;
    float: right;
    right: 15px;
    top: -13px;
    position: absolute;
  }
  h5 {
    text-align: left;
    margin-top: 12px;
    margin-bottom: 10px;
    font-weight: 400;
  }

  .order-placed-details {
    padding: 10px 10px;
    padding: 10px 21px;
    text-align: left;
    padding-left: 0;
  }
  .customer-details {
    padding: 5px 20px;
    background-color: #f1f1f4;
    border-top: 1px solid #e7e7f0;
    border-bottom: 1px solid #e7e7f0;
    margin-bottom: 20px;
  }
  // .product-datial-table {
  // padding-left: 20px;
  // padding-right: 21px;
  // }
  .table-bordered {
    border: none;
  }
  .product-datial-table table th:first-child {
    text-align: left;
    font-weight: 600;
    background: rgb(238, 238, 238);
    width: 25%;
  }
}

.statusicon {
  width: 100px;
  margin: 10px auto;
}
.react-select > div {
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  box-shadow: none !important;
  font-size: 14px;
  min-height: 42px;
}

.cross-right {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 999;
}

.slick-slider .slick-list .slick-track {
  width: max-content !important;
}

.affix {
  @include transition(
    $prop: all,
    $time: 0.3s,
    $ease: cubic-bezier(0.22, 0.61, 0.36, 1),
    $delay: 0s
  );
  opacity: 0;

  &.show {
    top: 0px;
    opacity: 1;
    position: fixed;
    background: rgba(255, 255, 255, 1);
    z-index: 999;
  }
}

.css-tlfecz-indicatorContainer {
  padding: 8px 3px;
}
.css-1okebmr-indicatorSeparator {
  display: none;
}

.Toastify__toast-container.Toastify__toast-container--top-right {
  top: 5em;
}

.how_to_redeem {
  padding: 30px 0;
  text-align: center;
  overflow: auto;
  // img{
  //     max-width: 100%;
  // }
}

@media (min-width: 991px) {
  .how_to_redeem img {
    max-width: 100%;
  }
}

.chip {
  display: inline-block;
  margin-top: 12px;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 25px;
  position: relative;
  .chip-value {
    display: inline-block;
    padding: 5px;
    padding-left: 10px;
    padding-right: 7.5px;
    background: #f8f8f8;
    color: #555151;
    font-weight: 500;
    border-radius: 6px 0 0 6px;
  }
  .chip-delete-button {
    background: #f8f8f8;
    color: #000000de;
    border: 0;
    border-radius: 0 6px 6px 0;
    // padding: 5px 10px;
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -25px;
    line-height: 0.5;
    font-weight: bold;
  }
}

.close-chip {
  font-size: 15px;
  height: 18px;
  width: 18px;
  padding-top: 4px;
  color: $colorPrimary;
  font-weight: 300;
}

.chip-selected {
  background: #fceef2 !important;
  color: #d72d5a;
  .eui-chip-value {
    color: #d72d5a !important;
    font-weight: 500;
  }
}
