@import "../../../../styles/_variables.scss";
@import "../../../../styles/_mixins.scss";

//Hamburger component
.hamburger {
  // &.navbar{
  //     padding: .5rem;
  // }
  .show {
    left: 0 !important;
  }
  .brand {
    margin-right: 4px;
    font-size: 1rem;
    display: inline-block;
    padding-top: 0.3125rem;
    padding-bottom: 0.3125rem;
    line-height: inherit;
    white-space: nowrap;
  }
  .plum-brand {
    height: 32px;
    width: 100%;
    padding: 1px 5px;
    display: inline-block;
    position: relative;
    margin: 0;
    .logo {
      max-height: 30px !important;
    }
  }
  .p-0 .nav-link {
    padding: 0;
  }
  .navbar-collapse {
    background: white;
    left: -100%;
    // for full screen  navbar
    height: 100vh !important;
    position: absolute;
    width: 100%;
    z-index: 99;
    top: 0px;
    overflow-y: scroll;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 0.5em;
    }
  }
  .collapse:not(.show) {
    // display: block;
  }
  .nav-item {
    padding-left: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .ctry-mb {
    background: none;
    left: 0 !important;
    height: auto !important;
    position: absolute;
    width: 100%;
    z-index: 99;
    top: 0px;
    .nav-item {
      padding-left: 0px;
      padding-top: 0px;
      padding-bottom: 10px;
    }
    overflow: unset !important;
  }
}
.box-bottom-shadow {
  box-shadow: 0px 6px 6px 0px rgba(209, 209, 209, 0.38);
}
.back-arrow-mb {
  font-size: 30px;
  vertical-align: text-bottom;
}

.country {
  color: #9f49ab;
}

.point-align {
  display: flex;
  align-items: center;
  color: #252a31;
  font-weight: 600;
  font-size: 12px;
  height: 28px;
}

.points-icon {
  margin-left: 6px;
  margin-right: 6px;
}
