@import '../styles/variables';
/* colors */

.text-primary {
  color: $colorPrimary !important;
}
.text-imp {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}

h1,
h2,
h3,
h4 {
  font-weight: $fontWeightMD;
  color: $colorDark;
}
h1 {
  font-size: 30px;
  line-height: $lineHieghtXL;
  font-weight: 600;
}
h2 {
  font-size: $fontSizeLG;
  line-height: $lineHieghtLG;
  margin-top: 1rem;
}
h3 {
  font-size: $fontSizeSM;
  line-height: $lineHieghtMD;
}
h4 {
  font-size: $fontSizeXS;
  line-height: $lineHieghtXS;
}
h5 {
  font-size: $fontSizeXS;
}

p {
  line-height: $lineHieghtMD;
}
span {
  &.primary {
    color: $colorPrimary;
  }
}
a {
  &.primary {
    color: $colorPrimary;
  }
}

a {
  color: $colorDark;
  &:hover {
    text-decoration: none;
    cursor: pointer;
    color: $colorPrimary;
  }
}

.link-secondary {
  color: $colorSecondary !important;
  a {
    color: $colorSecondary !important;
  }
}

.text-md {
  font-size: 14px;
}

.text-lg {
  font-size: $fontSizeLG;
}

.title {
  color: $colorDark;
}

.bodyText {
  color: $colorText;
}

strong {
  font-weight: $fontWeightMD;
}

.brand-color {
  color: $colorPrimary;
}
.text-black {
  color: #d1d5da;
}
.text-color {
  color: #fff;
}

.link {
  color: $colorPrimary;
  cursor: pointer;
  // text-decoration: underline;
  &:hover {
    color: darken($colorPrimary, 5%);
  }
}
ul {
  padding-left: 0px;
  li {
    // list-style: none;
  }
}
.cursor-pointer {
  cursor: pointer;
}
.link-color-blue {
  color: $colorPrimary !important;
}
.horzintol-line {
  border-top: 1px solid #eeeeee;
  width: 100%;
}
.text-align-l {
  text-align: left;
}
.title {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
}
.inp-header {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0px;
}
.inp-box {
  background-color: #ffffff;
  border-radius: 4px;
  height: 36px;
  min-height: 36px;
}
.text-css {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  font-style: normal;
  margin-bottom: 0px;
}
.text-sm {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
}
.info {
  color: #5f738c;
}
.mb-20 {
  margin: 0px 0px 20px 0px;
}
.mb-12 {
  margin: 0px 0px 12px 0px;
}

.mt-64 {
  margin-top: 64px;
}
.align-center {
  align-items: center;
}
.title-dark-black {
  color: $colorDark;
}
