/* =====================================

	BUTTONS

================================================================================================================ */

$buttonTransition: all 0.3s ease 0s;
.btn {
  // padding: 3px 25px;
  // border: 1px solid darken($colorPrimary, 5%);

  font-size: $fontSizeXS;
  font-weight: $fontWeightMD;
  outline: none !important;
  box-shadow: none !important;

  &.btn-primary {
    border: 1px solid darken($colorPrimary, 5%);
    color: $colorWhite;
    border-radius: $borderRadiusBtn;
    font-size: 13px;
    background-color: $colorPrimary;
    border-color: transparent;

    background-color: $colorPrimary;
    color: $colorWhite;
    border-radius: $borderRadiusBtn;
    // &:hover{
    //    background-color: darken($colorPrimary, 10%);
    //    transition: $transition;
    // }
    border-color: $colorPrimary;
    // border: 0;
    // border-radius: 0;

    position: relative;
    overflow: hidden;
    z-index: 0;
    // transition: $buttonTransition;
    &:after {
      content: "";
      position: absolute;
      display: block;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 100%;
      background: $colorPrimaryDark;
      border-color: black;
      z-index: -1;
      // transition: $buttonTransition;
    }
    &:hover {
      color: #fff;
      &:after {
        width: 100%;
      }
    }
  }
  &.btn-secondary {
    // border: 1px solid darken($colorSecondary, 5%);
    border: 1px solid $colorSecondaryNormal;
    background-color: $colorSecondaryNormal;
    color: $colorWhite;

    &:hover,
    &:focus,
    &:active,
    &:active:hover,
    &:active:focus {
      background-color: darken($colorSecondary, 5%);
    }
  }

  &.btn-default {
    border: 1px solid darken($colorDefault, 5%);
    background-color: $colorDefault;
    color: $colorWhite;
    &:hover,
    &:focus,
    &:active,
    &:active:hover,
    &:active:focus {
      background-color: darken($colorDefault, 5%);
    }
  }

  &.btn-transparent {
    border: none;
    color: $colorText;
    background-color: transparent;

    &.btn-border {
      border: 1px solid $borderColor;
    }
    &.btn-border-primary {
      border: 1px solid $colorPrimary;
      color: $colorPrimary;
      &:hover {
        background: $colorPrimary;
        color: $colorWhite;
      }
    }
  }

  &.btn-lg {
    padding: 10px 20px;
  }

  &.btn-md {
    padding: 6px 20px;
  }

  &.btn-sm {
    padding: 0px 6px;
    font-size: 12px;
  }

  &.btn-xs {
    padding: 2px 4px;
  }

  &.btn-has-icon {
    position: relative;
    top: 10px;
    left: 18px;

    i {
      font-size: 30px;
      position: relative;
      top: 8px;
    }

    &:hover {
      i {
        color: $colorPrimary;
        font-weight: $fontWeightMD;
        right: -5px;
      }
    }
  }
}

.btn-primary[disabled]:hover {
  background: $colorPrimary;
  color: #fff;
}
.btn-wrapper {
  margin: 40px 0;
}

.btn-outline-secondary {
  color: #333333;
  border-color: #333333;
  &:hover {
    background: $colorPrimary;
    color: #fff;
    border: 1px solid $colorPrimary;
  }
  //  border-color: $colorPrimary;
  // border: 0;
  // border-radius: 0;
  // color: $colorPrimary;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: $buttonTransition;
  &:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 100%;
    background: $colorPrimaryDark;
    z-index: -1;
    // transition: $buttonTransition;
  }

  &:hover {
    color: #fff;
    &:after {
      width: 100%;
    }
  }
}
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show > .btn-outline-primary.dropdown-toggle {
  background: $colorPrimary;
  border-color: $colorPrimary;
}
.btn-outline-primary {
  color: $colorPrimary;
  border-color: $colorPrimary;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  &:hover {
    background: transparent;
    color: #fff;
    border: 1px solid $colorPrimary;
  }

  border-color: $colorPrimary;
  // border: 0;
  //   border-radius: 0;
  color: $colorPrimary;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: $buttonTransition;
  &:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 100%;
    background: $colorPrimaryDark;
    z-index: -1;
    // transition: $buttonTransition;
  }

  &:hover {
    color: #fff;
    &:after {
      width: 100%;
    }
  }
}

.fifth {
  border-color: $colorPrimary;
  // border: 0;
  border-radius: 0;
  color: $colorPrimary;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: $buttonTransition;

  &:after {
    content: "";
    position: absolute;
    display: block;
    top: 0;

    transform: translateX(-50%);
    width: 0;
    height: 100%;
    background: $colorPrimary;
    z-index: -1;
    // transition: $buttonTransition;
  }

  &:hover {
    color: #fff;
    &:after {
      width: 100%;
    }
  }
}
/* =====================================

	FORM INPUTS

================================================================================================================ */

.form-group {
  margin-bottom: 20px;

  .input-error {
    font-size: 12px;
    display: block;
    padding: 1px 5px;
    font-weight: 600;
    color: #f44336;
  }
}

.form-check-input {
  margin-left: 0;
}
.form-control {
  color: $colorDark !important;
  border: 1px solid $borderColorInput;
  border-radius: $inputBorderRadiusSize;
  box-shadow: none !important;
  font-size: $fontSizeSM;
  min-height: 42px;
  margin-right: 0;

  &:focus {
    box-shadow: none;
    outline: none;
    border-color: $colorPrimary;
  }

  & + {
    .SingleDatePicker {
      position: relative;
      top: -43px;
      width: 98%;
      left: 1%;

      .DateInput--with-caret:before {
        top: 45px;
      }
      .DateInput--with-caret:after {
        top: 46px;
      }

      .SingleDatePicker__picker {
        top: 53px;
        left: -3px;
        z-index: 2 !important;
      }
    }
  }

  &.input-sm {
    min-height: 26px;
  }
}

select.input-lg {
  height: 46px;
  line-height: 1;
}
.rotate {
  transform: rotate(360deg) !important;
}

/***** INPUT *****/
input[type="radio"] {
  -webkit-appearance: radio;
  -moz-appearance: radio;
  appearance: radio;
}

// .select-dropdown {
// 	position: relative;
//     width: auto;
//     display: inline-block;
//     max-width: 100%;
//     border-radius: 2px;
//     border: 0px solid #ccc;
// 	margin-right: 2px;
// 	&.value{
// 		&:after{
// 			right : 0!important;
// 		}
// 	}
//   select {
// 	max-width: 100%;
// 	font-size: 14px;
// 	min-height: auto;
// 	height: auto;
//     padding: 2px 16px 2px 4px;
//     border: none;
//     background-color: transparent;
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
//     &:active,
//     &:focus {
//       outline: none;
//       box-shadow: none;
//     }
//   }
//   &:after {
// 	content: "\f107 ";
//     font-family: 'FontAwesome';
//     font-size: 14px;
//     position: absolute;
//     top: 50%;
//     margin-top: -11px;
//     right: 12px;
//     width: 0;
//     height: 0;
//   }
// }

.select-amount {
  margin-left: 3px;
}
.select-dropdown {
  position: relative;
  // width: auto;
  display: inline-block;
  width: 84%;
  // border-radius: 2px;
  border: 0px solid #ccc;
  // margin-right: 4px;
  // &.value{
  // 	&:after{
  // 		right : 0!important;
  // 	}
  // }

  select {
    font-weight: 500;
    //   margin: 50px;
    width: 100%;
    padding: 0px 10px 0px 1px;
    font-size: 14px;
    height: 34px;
    //   -webkit-appearance: none;
    //   -moz-appearance: none;
    //   appearance: none;
    border: none;
    //   background: url('/img/icons/down-arrow.svg') 98% / 11px no-repeat transparent;
    -webkit-appearance: none;
    /* for webkit browsers */
    -moz-appearance: none;
    /* for firefox */
    appearance: none;
  }
  label {
    margin: 0;
    display: inline;
  }
}

@media only screen and (min-width: 992px) {
  .select-dropdown {
    position: relative;
    // width: auto;
    display: inline-block;
    width: 100%;
    // border-radius: 2px;
    border: 0px solid #ccc;
    margin-right: 0px;
    &.value {
      &:after {
        right: 0 !important;
      }
    }

    select {
      width: 100%;
      padding: 0px 17px 0px 1px;
      font-size: 14px;
      height: 34px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: none;
      background: url("/img/icons/down-arrow.svg") 100% / 10px no-repeat
        transparent;
      -webkit-appearance: none;
      /* for webkit browsers */
      -moz-appearance: none;
      /* for firefox */
      appearance: none;
    }
    label {
      margin: 0;
      display: inline;
    }
  }
}

#gc_price + label:after {
  background: url(/img/icons/down-arrow.svg) no-repeat transparent;
  background-size: 10px 10px;
  display: inline-block;
  width: 10px;
  height: 15px;
  position: absolute;
  top: 18px;
  right: -4px;
  content: "";
}
#gc_qty + label:after {
  background: url(/img/icons/down-arrow.svg) no-repeat transparent;
  background-size: 10px 10px;
  display: inline-block;
  width: 10px;
  height: 15px;
  position: absolute;
  top: 18px;
  right: -4px;
  content: "";
}

/* CAUTION: IE hackery ahead */
select::-ms-expand {
  display: none; /* remove default arrow on ie10 and ie11 */
}

/* target Internet Explorer 9 to undo the custom arrow */
@media screen and (min-width: 0\0) {
  select {
    background: none\9;
    padding: 5px\9;
  }
}

/* ---------------------------
	PLACEHOLDERS
------------------------------------------------------------------------------------------------------- */

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: lighten($colorText, 9%);
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: lighten($colorText, 9%);
}

/* ---------------------------
	LABEL
------------------------------------------------------------------------------------------------------- */

label {
  font-weight: $fontWeightMD;
  font-size: $fontSizeXS;
  cursor: pointer;
  margin-left: 0px;
}

.input-title {
  font-size: $fontSizeSM;
  font-weight: $fontWeightMD;
  margin-left: 0;

  &.title-lg {
    font-size: $fontSizeLG;
    font-weight: $fontWeightSX;
  }
}

input[type="radio"] {
  width: 20px;
  height: 20px;
  margin: 0px 10px 0px 0px;
}

//radio Button
input[type="radio"] {
  @include ad_appearance(none);
  @include ad_variableborder(2px, #131416);
  @include ad_borderRadius(50%);
}
input[type="radio"]:checked {
  @include ad_appearance(none);
  @include ad_variableborder(2px, #131416);
  @include ad_borderRadius(50%);
  &:focus {
    outline: none;
  }
}
input[type="radio"]:checked:before {
  background: #131416;
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

///// checkbox

$md-checkbox-checked-color: $gray400;
$md-checkbox-checked-color-disabled: rgba(0, 0, 0, 0.26);

$md-checkbox-margin: 0.5em 0;
$md-checkbox-size: 1.2em;
$md-checkbox-padding: 0.25em;
$md-checkbox-border-width: 1px;
$md-checkbox-border-radius: 0.125em;
$md-checkmark-width: 0.14em;
$md-checkmark-color: #333;
$md-checkbox-label-padding: 0.5em;

//   .md-checkbox input[type=checkbox]:checked + label:before{
//                 background: ${primary}
//             }

.md-checkbox {
  position: relative;
  margin: $md-checkbox-margin;
  text-align: left;

  &.md-checkbox-inline {
    display: inline-block;
  }

  label {
    cursor: pointer;
    display: inline;
    line-height: 1.6em;
    vertical-align: top;
    clear: both;
    padding-left: 1px;
    font-weight: 400;
    &:not(:empty) {
      padding-left: $md-checkbox-label-padding;
    }

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 6px;
      top: 6px;
    }

    &:before {
      // box
      width: $md-checkbox-size;
      height: $md-checkbox-size;
      background: #fff;
      border: $md-checkbox-border-width solid #c3c2c9;
      border-radius: $md-checkbox-border-radius;
      cursor: pointer;
      transition: background 0.3s;
    }

    &:after {
      // checkmark
    }
  }

  input[type="checkbox"] {
    outline: 0;
    visibility: hidden;
    width: $md-checkbox-size;
    margin: 0;
    display: block;
    float: left;
    font-size: inherit;

    &:checked {
      + label:before {
        // background: $md-checkbox-checked-color;
        // border:none;
      }
      + label:after {
        $md-checkmark-size: $md-checkbox-size - 2 * $md-checkbox-padding;

        transform: translate(
            $md-checkbox-padding,
            calc($md-checkbox-size / 2) - calc($md-checkmark-size / 2.6)
          )
          rotate(-45deg);
        width: $md-checkmark-size;
        height: calc($md-checkmark-size / 2);

        border: $md-checkmark-width solid $md-checkmark-color;
        border-top-style: none;
        border-right-style: none;
      }
    }

    &:disabled {
      + label:before {
        border-color: $colorDefault;
      }
      &:checked {
        + label:before {
          background: $md-checkbox-checked-color-disabled;
        }
      }
    }
  }
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #e9ecef !important;
  background-image: none !important;
}

.disable-qty {
  margin: 0 !important;
  background: #e9ecef;
  padding: 0 10px;
}
