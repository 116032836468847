body {
  font-size: $fontSizeXS;
  color: #0a0a0a !important;
  background-color: #fafafa !important;
  font-family: $fontFamily;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  padding-right: 0;
  line-height: $lineHieghtSM;
  overflow-y: scroll;
  // position:relative;
}
hr {
  border-top: 1px solid #CACACA;
}

@media (min-width: 1440px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1380px;
  }
}

.fs-14 {
  font-size: 14px !important;
}

.fs-12 {
  font-size: 12px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fw-500 {
  font-weight: 500;
}
.inkNormal {
  color: #24292e !important;
}
.inkLight {
  color: #5f738c !important;
}
.secondary {
  color: $colorSecondary;
}

.showOneline,
.showOneLine span {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.showTwoline {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.border-radius {
  border-radius: $borderRadiusSize;
}

.box-shadow {
  box-shadow: $boxShadowHuge;
}

.svg-icon {
  width: 16px;
  margin-right: 10px;
  margin-top: 10px;
}
.svg-white {
  filter: brightness(0) invert(1);
}
.btn-link {
  background: transparent !important;
  border: none !important;
  color: #666 !important;
}

.pageNotFound {
  img {
    width: 450px;
    height: 450px;
    @media screen and (max-width: 991px) {
      width: 250px;
      height: 250px;
    }
  }
}

.pageForbidden {
  img {
    width: 300px;
    height: 300px;
    @media screen and (max-width: 991px) {
      width: 200px;
      height: 200px;
    }
  }
}

a {
  text-decoration: none !important;
}

.shimmer-effect {
  -webkit-animation: fadein 0.5s ease-in alternate infinite;
  -moz-animation: fadein 0.5s ease-in alternate infinite;
  animation: fadein 0.5s ease-in alternate infinite;
}
