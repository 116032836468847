body {
  @media (max-width: 991px) {
    background-color: #fcfcfc;
  }
}

#faq-bot-button {
  right: 25px;
  bottom: 30px;
}

#faq-bot-button {
  right: 25px;
  bottom: 30px;
}
// .hs-messages-mobile {
//     &.hs-messages-widget-open {
//        #hubspot-messages-iframe-container iframe{
//             bottom: 0px !important;
//         }
//     }

//     #hubspot-messages-iframe-container iframe{
//         bottom: 32px !important;
//     }
// }

#webklipper-publisher-widget-container-notification-frame {
  width: 100%;
}

.gateway-msg {
  margin-bottom: 60px;
}

.fab-button {
  height: 48px;
  background-color: #fff;
  color: $colorDark;
  padding: 12px 0px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  left: 0px;
  cursor: pointer;
  box-shadow: 0px -2px 5px #0000002b;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  z-index: 200;
}

.fab-btn {
  background: #55c0c9;
  width: 60px;
  height: 60px;
  border-radius: 100%;
  position: fixed;
  bottom: 17.5%;
  right: 4.5%;
  cursor: pointer;
  box-shadow: 0px 2px 10px 7px rgba(64, 64, 64, 0.16) !important;
  z-index: 110;
  color: #fff;
  background-color: #55c0c9 !important;
  border-color: #55c0c9 !important;
  & img {
    color: #fff;
    text-align: center;
    position: relative;
    left: -5px;
  }
}
#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  cursor: pointer;
}
.fab-menu {
  &.dropdown-menu {
    &.show {
      will-change: transform;
      transform: translate3d(180px, 367px, 0px);
      line-height: 32px;
      border-radius: 13px;
      position: fixed;
      bottom: auto;
      top: -9px !important;
      left: -18px !important;
    }
  }
}

.mobile-footer {
  position: fixed;
  transform: translate3d(0,0,0); //fix for iOS
  z-index: 9;
  margin-left: 0;
  left: 0;
  bottom: 60px;
  padding: 8px;
  box-shadow: 0px -12px 24px rgba(10, 10, 10, 0.04);
  background: #fff;
  width: 100%;
  // display:block;
}

.xs-search {
  & span:hover {
    cursor: pointer;
  }
}

@media (width: 768px) {
  .m-p-0 {
    margin-left: -15px;
    margin-right: 0px;
    width: 768px;
  }
}

@media (max-width: 991px) {
  // ::-webkit-scrollbar {
  //   -webkit-appearance: none;
  //   height: 5px;
  // }
  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  .slick-slider .slick-arrow.slick-prev,
  .slick-slider .slick-arrow.slick-next {
    display: block !important;
    top: 30%;
  }

  .logo {
    top: 2px;
  }

  .Home_Modal {
    .nav-item {
      width: 50%;
      text-align: center;
    }
    .modal-content {
      padding: 0px;
    }
  }

  .how_to_redeem {
    overflow: auto;
    img {
      width: auto;
    }
  }

  .modal {
    .validityModal.modal-dialog {
      width: 100%;
      .modal-body {
        width: 100%;
        padding-top: 4%;
      }
    }
  }

  .m-p-0.mobile-slider .slick-slider .slick-list .slick-track {
    height: 300px;
  }
  .book-now-block {
    .ps-3 {
      padding-left: 0.5rem !important;
    }
    .pe-3 {
      padding-right: 0.5rem !important;
    }
    .ms-1 {
      margin-left: 0.1rem !important;
    }

    .m-sticky-header {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 1030;
      background: #fff;
      line-height: 25px;
      & + .container {
        margin-top: 66px;
      }
    }
  }

  #faq {
    ~ .row {
      .list {
        //  padding-bottom: 40px;
      }
    }
  }
  .m-p-0 {
    padding: 0;
  }
  .m-sticky-nav {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    background: #fff;
    padding-bottom: 12px;
    & + .container {
      margin-top: 76px;
    }
  }

  .search-box-top {
    box-shadow: 0px 12px 24px 0px #0A0A0A0A;
    background-color: #ffffff;
  }

  .m-sticky-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
    background: #fff;
    & + .container {
      margin-top: 66px;
    }
  }

  .m-disc-header {
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background: #fff;
    height: 120px;
    margin-top: -117px;
    padding-top: 30px;
    z-index: 99;
    position: relative;
  }
  .buyNow-Header {
    // padding-left: 15px;
    .text-color {
      color: #333;
    }
  }
  .modal {
    padding: 0 !important;
    .modal-title {
      width: 100%;
    }
    .modal-dialog {
      width: 100%;
      height: 100%;
      max-width: 100%;
      margin: 0;
      padding: 0;
    }

    .modal-content {
      height: auto;
      min-height: 100%;
      border: 0 none;
      border-radius: 0;
      &::-webkit-scrollbar {
        width: 3px;
      }
    }
  }

  .blacklistPordModal {
    display: flex;
    align-items: end;
    min-height: calc(100% - 1rem);
    .modal-content {
      min-height: unset;
    }
  }

  .banner-modal-body {
    flex: unset;
  }

  .banner-modal-footer.modal-footer {
    padding: 0rem 2rem 1rem 2rem;
    .xs-search {
      border-bottom: 1px solid $borderColor;
      padding: 2px 0 10px 0px;
    }
  }
  .gift-card .form-control {
    width: 88%;
  }
  .login-modal {
    .cross-right {
      position: absolute;
      right: 15px;
      top: 15px;
      background: #fff;
      border-radius: 100%;
      padding: 7px 9px;
      .svg-icon {
        width: 16px;
        position: relative;
        left: 5px;
        top: -5px;
        right: auto;
        bottom: auto;
      }
    }
    .modal-content {
      background: $colorPrimary
        url(https://res.cloudinary.com/dyyjph6kx/image/upload/v1574430739/store/homepage/login-bg.png);
      background-size: cover;
    }
    .login-card {
      background: #fff;
      margin-left: 5%;
      margin-right: 5%;
      padding: 12% !important;
      margin-top: 25%;
      box-shadow: $boxShadowLite;
      border-radius: $borderRadiusSize;
      border: 1px solid $borderColor;
    }
  }
}

.filterMobileModal {
  bottom: 0px;
  height: max-content;
  top: unset;
}

.filter-modal {
  margin: -1px;
  height: 100%;
  .modal-content {
    .modal-body {
      margin-top: 62px;
      margin-bottom: 60px;
    }
  }
}

.active-dot {
  svg {
    circle {
      fill: $colorPrimary;
    }
  }
}