@import "../../../styles/_variables.scss";
.footer {
  background-color: #0a0a0a;
  padding: 50px 30px 10px;
  z-index: 0;
  position: relative;

  .link:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  a:hover {
    svg {
      path {
        fill: $gray500;
      }
    }
  }

  ul {
    list-style: none;
    // line-height: 40px;
    margin-bottom: 38px;

    li,
    a {
      cursor: pointer;
      color: #cacaca;
      &:hover {
        color: #f2f3f2;
      }
    }
  }
  .social {
    li {
      display: inline;
      margin: 5px;
    }
  }
}
.copy-right {
  border-top: 1px solid #4a4a4a;
  .bottom-navlink {
    color: #6a6a6a;
    &:hover {
      color: #8a8a8a;
    }
  }
}
.purple {
  color: $colorPrimary;
}
