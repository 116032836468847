@import "../../../../../styles/_variables.scss";

.header .header-search {
  color: #969696;
  font-size: 14px;
  &::placeholder {
    font-size: 14px;
    color: #6a737d;
  }
  .form-control:focus {
    color: #969696;
    font-size: 14px;
  }
}
.searchsuggest {
  font-size: 14px;
  position: relative;
  border-radius: 4px;
  box-shadow: 0 3px 6px #00000029;
  background: #ffffff;
  z-index: 9999 !important;
  position: absolute;
  width: 100%;
  top: 55px;
  overflow: auto;
  max-height: 300px;
  .searchResult:hover {
    background-color: #f6f6f6;
  }
  .text-main {
    // color: #707070;
    padding: 0.5rem 1em;
    .imgwrap {
      img {
        height: 30px;
        width: 30px;
      }
    }
    .font-weight-bold {
      color: #000000;
    }
  }
  .noserch {
    font-size: 12px;
    line-height: 16px;
  }
  li:nth-child(n) {
    //   border-bottom: 1px solid #00000029;
  }
  li:last-child {
    border-bottom: none;
  }
  li:first-child {
    // background-color: #f7f7f7;
    &:before {
      content: "";
      border-width: 0 10px 12px;
      border-color: transparent transparent #ffffff;
      position: absolute;
      display: inline-block;
      top: -11px;
      left: 45px;
      border-style: solid;
    }
  }
  li {
    &:hover {
      background-color: #f7f7f7;
    }
  }
}

.back-btn {
  padding-left: 18px;
  font-size: 33px;
  font-weight: lighter;
  padding-top: 8px;
  color: $colorPrimary;
}

.search-bar {
  padding: 0;
  padding-top: 5px;
  padding-bottom: 5px;
  input {
    border: none;
    margin-left: -10px;
    font-size: 13px;
  }
}

.search-mb {
  .search-count {
    font-size: 12px;
  }
  .search-type {
    font-size: 12px;
    text-align: end;
    color: #893b8e;
  }
}

.mb-srch-cancel {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.search-mobile {
  background-color: #f6f8fa;
  padding: 8px;
  border-radius: 6px;
}

.search-mobile-text {
  color: #6a737d;
  font-size: 14px;
  font-weight: 400;
}

.search-icon-mobile {
  position: relative;
  top: -1px;
  margin-left: 3px;
  margin-right: 11px;
}

.headerSearch {
  background-color: #F6F6F7;
  border-radius: 4px;
  width: 600px;
}
