@import "../../../styles/_variables.scss";

//Banner Image MOdal
.banner-modal-body {
  top: -25px;
  padding: 0px 5rem 1rem;
}

.banner-modal-footer {
  display: block;
  border: none;
  padding: 1rem 5rem;
}

.login-modal {
  .login-banner {
    width: 100%;
    img {
      // position: relative;
      top: -40px;
      left: -40px;
    }
  }
  //overflow: auto;
  span {
    cursor: pointer;
  }
}

@media (min-width: 992px) {
  .modal-country {
    &.modal-dialog {
      max-width: 650px;
    }
  }
}

.banner-modal-footer {
  display: block;
  border: none;
  padding: 0rem 5rem 1rem 5rem;
}

.usermodal {
  @media screen and (min-width: 992px) {
    &.modal-dialog {
      right: -333em;
      margin-right: 0;
      position: absolute;
      top: 0;
      margin-top: 0;
      width: 30%;

      .modal-content {
        height: 100vh;
      }
    }
  }
  @media screen and (max-width: 991px) {
    &.modal-dialog {
      margin-right: 0;
      margin-left: 0;
      position: absolute;
      top: 0;
      margin-top: 0;
      width: 100%;

      .modal-content {
        height: 100vh;
      }
    }
  }

  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem auto -1rem -6rem;
  }
  .modalHeader {
    align-items: center;
    border-bottom: 0 none;
  }
  .close {
    span {
      font-weight: 100;
      font-size: 24px;
    }
  }
}
.modal.show .modal-dialog.usermodal {
  right: 0;
}

.modal-dialog.usermodal {
  display: block;
  transition: all ease-in 100ms !important;
}

.mb-title {
  display: inline;
  vertical-align: sub;
  color: $colorPrimary;
}
.pointer {
  cursor: pointer;
}
.purpleText {
  color: $colorPrimary;
  // color: rgba(102, 40, 107, 1);
}
.signUpModal {
  width: 75%;
}
.close-icon {
  height: 13px;
  margin-top: 5px;
  margin-right: 5px;
  position: relative;
  left: -10px;
  cursor: pointer;
}

.svg-icon {
  &.cross {
    width: 16px;
    margin-right: 15px;
    margin-top: 15px;
  }
}
.tnc {
  border: 0px;
  border-bottom: 0px solid #d1d5da;
  .tab-w {
    display: inline-block;
    text-align: center;
    width: auto;
  }
  .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    font-size: 16px;
    font-weight: normal;
    border: none;
  }
  thead {
    background: #f3f3f3;
    color: #333;
  }
  a.nav-link {
    text-decoration: none;
    color: #333;
    font-weight: 500;
    padding-left: 0;
    @media (max-width: 992px) {
      border-bottom: 2px solid #e3e3e3;
    }
  }
}
.howto {
  .modal-header {
    border-bottom: none;
  }
}

.tnc.nav-tabs .nav-item.show .nav-link,
.tnc.nav-tabs .nav-link.active {
  color: #9f49ab;
  border-bottom: 2px solid #9f49ab;
  font-weight: normal;
  background: transparent;
  font-weight: 500;
}

.copytext {
  text-align: center;
  background: #eee;
  padding: 5px 18px;
  color: #646464;
  border-radius: 8px;
}

.icons {
  max-width: 14px;
  margin-right: 5px;
}

.link-css,
.link-css:hover {
  color: #4382e0;
  text-decoration: underline;
}

.validityModal {
  .modal-content {
    @media (min-width: 992px) {
      border-radius: 13px;
      // height: 286px !important;
      width: 700px !important;
      position: absolute;
      background: #ffffff;
      border: 0px none;
      box-shadow: -4px 4px 20px rgba(10, 10, 10, 0.04),
        4px -4px 20px rgba(10, 10, 10, 0.04) !important;
      border-radius: 8px !important;
      justify-content: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: block !important;
    }
  }
  .modal-header {
    padding: 16px 20px;
    box-shadow: inset 0px -1px 0px #eeeeee;
    align-items: center;
  }
  .modal-footer {
    border-top: none;
    justify-content: center;
  }
  .modal-body {
    padding: 32px 20px;
    width: auto !important;
    @media (max-width: 991px) {
      padding: 16px !important;
    }
    .codeValue {
      input {
        text-transform: uppercase;
      }
      width: 400px;
      margin: 0 auto;
      @media (max-width: 991px) {
        width: 300px !important;
      }
    }
  }
  ::placeholder {
    color: #0000004d;
    opacity: 1;
    font-size: 12px; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #0000004d;
    opacity: 1;
    font-size: 12px;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #0000004d;
    opacity: 1;
    font-size: 12px;
  }
  .checkVoucher {
    text-decoration: underline !important;
    color: $colorPrimary !important;
    font-weight: 500;
  }
  .xoxo-title {
    @media (max-width: 992px) {
      padding-left: 72px;
      font-size: 18px;
      color: $colorPrimary;
    }
  }
}

@media (max-width: 991px) {
  .modal.fade.show .modal-dialog.getoffer {
    -webkit-transform: translate(0, -25%);
    -ms-transform: translate(0, -25%);
    -o-transform: translate(0, -25%);
    transform: translate(0, -25%);
    position: fixed;
    top: 80%;
  }
}

.getoffer {
  @media (max-width: 991px) {
    .modal-content {
      height: auto;
      min-height: 50%;
      border: 0 none;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      overflow: auto;
      max-height: 50vh;
    }
  }
}

.howToRedeem {
  margin: 0px 16px;
  @media (max-width: 991px) {
    margin: 0;
  }
}

.Home_Modal {
  &::-webkit-scrollbar {
    width: 4px;
  }
  padding: 20px;
  @media (max-width: 991px) {
    padding: 0px !important;
    .modal-content {
      width: 100% !important;
    }
  }
  .modal-content {
    width: 660px;
    overflow: hidden;
  }
  .to-redeem-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #6a6a6a;
  }
  .modal-header {
    padding: 16px 20px;
    @media (max-width: 991px) {
      padding: 12px 16px;
    }
    align-items: center;
    box-shadow: inset 0px -1px 0px #eeeeee;
    border: 0;
  }
  .modal-footer {
    padding: 20px 32px;
    justify-content: flex-start;
    border: unset;
    background-color: #fafafa;
  }
}

.redeemSteps {
  margin-top: 32px;
  margin-bottom: 32px;
  &:first-child {
    margin-top: 16px;
  }
  &:last-child {
    margin-bottom: 0px;
  }
  .mt-5 {
    margin-top: 5px !important;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .imgDiv {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #ebfaff;
    position: relative;
    img {
      height: 16px;
      width: 16px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .learn-more {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4382e0;
    margin-top: 10px;
  }
}

.addDeliveryEmail {
  top: 50%;
  transform: translate(0%, -50%) !important;
  width: 600px;
  .modal-content {
    border-radius: 8px;
  }
  .modal-header {
    padding: 20px;
    .modal-title {
      color: #252a31;
    }
  }
  .modal-body {
    padding: 32px 24px;
    .title {
      color: #252a31;
      font-weight: 400;
      margin-bottom: 10px;
    }
  }
  .float-label {
    display: flex;
    flex-direction: column;
    // min-width: 350px;
    position: relative;
    input {
      border-radius: 4px;
      font-size: 16px;
      padding-top: 20px;
      &:disabled {
        background-color: #e9e9e9;
      }
    }
    label {
      font-size: 16px;
      padding: 0 12px;
      color: #999;
      pointer-events: none;
      position: absolute;
      top: -7px;
      transform: translate(0, 26px) scale(1);
      transform-origin: top left;
      transition: all 0.2s ease-out;
    }
  }

  .float-label:focus-within label {
    transform: translate(0, 12px) scale(0.75);
    padding: 0 18px;
    color: #6a737d;
  }

  .float-label .active {
    transform: translate(0, 12px) scale(0.75);
    padding: 0 18px;
    // color: $adprimarycolor;
  }
  .modal-footer {
    background-color: #f5f7f9;
    border-top: unset;
    .btn {
      font-size: 14px;
    }
    .primaryBtn {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}

.accountTypeBadge {
  padding: 8px;
  font-size: 12px;
  font-weight: 500;
  margin-right: 8px;
  margin-bottom: 8px;
  &.admin {
    background-color: #e8f4fd;
    color: #005aa3;
    border: 1px solid #0172cb;
  }
  &.green {
    color: #2b7336;
    background-color: #ebf4ec;
    border: 1px solid #28a138;
  }
  &.modules {
    background-color: #f5f7f9;
    border: 1px solid #bac7d5;
    color: #5f738c;
  }
}

.linkedAccTitle {
  border-bottom: unset;
  margin-bottom: 8px;
  padding: 0px 32px;
  .modal-title {
    font-weight: normal;
    color: #000;
    @media screen and (max-width: 991px) {
      font-size: 11px;
    }
  }
}

.listItem {
  padding: 0 !important;
  margin: 14px 0px;
  border: none;
  .accountData {
    padding-left: 24px;
    padding-right: 32px;
    img {
      height: 40px;
      width: 40px;
    }
    .accountTitle {
      margin-left: 24px;
      width: 75%;
      margin-right: 16px;
    }
    @media screen and (max-width: 991px) {
      padding-left: 0px;
      padding-right: 0px;
      img {
        height: 32px;
        width: 32px;
      }
      .accountTitle {
        margin-left: 12px;
        width: 70%;
        margin-right: 12px;
      }
    }
    .redirectionLabel {
      .lb {
        color: #000;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  &:hover {
    background-color: #ebfaff;
    border-radius: 8px;
  }
}

.accountsList {
  max-height: 360px;
  overflow-y: auto;
  margin-left: 8px;
  margin-right: 8px;
  @media screen and (max-width: 991px) {
    max-height: 330px;
    padding-right: 4px;
    &::-webkit-scrollbar {
      width: 2px;
    }
  }
  // padding-left: 24px;
  // padding-right: 24px;
}

.linkedAccounts {
  .linkedAccountsBody {
    padding-top: 37px;
    padding-bottom: 25px;
    .loadingContent {
      .compLoader {
        top: 50%;
      }
    }
  }
  @media screen and (max-width: 991px) {
    .modal-content {
      height: 100%;
      .linkedAccountsBody {
        background-color: #fff;
        border-radius: 4px;
        position: relative;
        top: 50%;
        transform: translate(0, -50%);
        margin: 0 20px;
        box-shadow: 0px 0px 3px 2px #fff;
        border: solid 1px #e9e9e9;
        padding-top: 24px;
        padding-bottom: 24px;
        .modal-body {
          padding: 0;
          .listItem {
            padding: 12px !important;
            border: solid 1px #bac7d5;
            border-radius: 10px;
          }
        }
      }
    }
  }
}

.currencyBadge {
  color: $colorSecondary;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
}


.header-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #d72d5a;
}

.enter-code-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #0a0a0a;
}

.check-btn {
  width: 120px;
  height: 42px;
  background-color: #ffffff !important;
  border: 1px solid #0a0a0a !important;
  :disabled {
    border: 1px solid #dcdcdc !important;
  }
}

.check-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  color: #2a2a2a;
  :disabled {
    color: #aaaaaa;
  }
}

.hint-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #6a6a6a;
  margin-top: 4px;
}

.modal .validityModal.modal-dialog .modal-content .form-control:focus {
  border: 1.5px solid #6a6a6a !important;
}

.balance-main {
  margin-top: 32px;
  .balance-text {
    // display: inline-block;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    .balance-amount {
      color: #179352;
    }
    .incorrect-text {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #b50b0b;
    }
  }

  .check-link {
    // display: inline-block;
    float: right;
    padding-right: 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    align-items: center;
    text-align: right;
    text-decoration-line: underline;
    color: #0a0a0a;
    cursor: pointer;
  }
}

.checkout-verify-text {
  font-weight: 600;
  font-size: 22px;
  line-height: 34px;
  text-align: center;
  color: #0a0a0a;
}

.otp-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #6a6a6a;
}
.otpInput {
  margin-top: 32px;
  // margin-bottom: 40px;
}
.otp-input-background {
  background-color: #f2f3f2;
}

.otpInput .otp-input:focus {
  background: #ffffff;
  border: 1px solid #6a6a6a !important;
  border-radius: 4px;
}

.verify-otp {
  border-radius: 4px !important;
  color: #ffffff !important;
  background: #2a2a2a !important;
  &:disabled {
    background: #aaaaaa !important;
  }
}

.resend-otp {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #0a0a0a;
}

.otp-expire {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #6a737d;
  margin-bottom: 20px;
  margin-top: 40px;
}

.otp-background {
  background-color: #6a737d;
  border: none;
}

.border-0 {
  border: none;
}

.btn-primary-css {
  color: #fff !important;
  background: #c7417b !important;
  border: 1px solid #c7417b !important;
  padding: 8px 30px;
}
.btn-primary-css:hover {
  color: #fff !important;
  background: #c7417b !important;
  border: 1px solid #c7417b !important;
}
.modal-dialog {
  @media (min-width: 576px) {
    max-width: 600px;
  }
}
.text-overflow-wrap {
  overflow-wrap: break-word;
}
