$colorPrimary100: #E7EEFD;
$colorPrimary: #1D61F6;
$colorPrimaryDark: #0A51EB;

$colorDefault: #8e8e8e;
$colorSecondaryLight: #ebfaff;
$colorSecondaryNormal: #36b4c3;
$colorSecondary: #0b7c89;

$transition: 0.3s;

$colorBlue: #55c0c9;

$colorText: #646464; //#484848;

$colorTextLite: lighten($colorText, 30%);

$colorBackground: #ffffff;

$lightGrey: #9f9f9f;

$colorDull: #707070;

$colorWhite: #ffffff;

$colorStar: #ff9800;

$colorgrey: #999999;
$colorgray: #808080;
$gray-border: #f3f3f4;

$colorLightgreyish: #707070;

$colorDark: #24292e;
// $colorDark 				:#000000;
$sky-blue: #36849e;

/*****Cards******/

$card-shadow: 0px 3px 6px #64646429;
$card-radius: 4px;
$card-img-height: 56.5%; //362/204 ratio as per design
$card-content-pading: 16px 22px 20px;

/*---------------------------
|  cardGradient
-------------------*/
$gradientprimarydark: rgba(99, 38, 103, 1);

$gradientprimarylight: rgba(159, 73, 171, 1);

/* ---------------------------
	FONT SIZE
------------------------------------------------------------------------------------------------------- */

$fontSizeXS: 14px;

$fontSizeSM: 16px; //14

$fontSizeLG: 24px;

$fontSizeXXL: 40px;

$fontFamily: "Inter", sans-serif;

/***** Line Height ****/

$lineHieghtXS: 19px;

$lineHieghtSM: 21px;

$lineHieghtMD: 24px;

$lineHieghtLG: 32px;

$lineHieghtXL: 40px;

/* ---------------------------
	FONT WEIGHT
------------------------------------------------------------------------------------------------------- */

$fontWeightSX: 300;

$fontWeightSM: 400;

$fontWeightMD: 500;

$fontWeightLG: 600;

$fontWeightXL: 700;

/* ---------------------------
	BORDER RADIUS
------------------------------------------------------------------------------------------------------- */

$borderRadiusSize: 4px;
$borderRadiusBtn: 4px;
$boxShadowBtn: 0px 3px 6px #00000029;
$inputBorderRadiusSize: 2px;

/* ---------------------------
	BORDER COLOR
------------------------------------------------------------------------------------------------------- */

$borderColor: #e3e3e3;
$borderColorInput: darken($borderColor, 5%);
$borderColorHover: darken($borderColor, 8%);
$borderDark: #dbdbdb;
$borderColorMain: #eeeeee;

/* ---------------------------
	BOX SHADOW
------------------------------------------------------------------------------------------------------- */

$boxShadowLite: 1px 1px 9px #f7f7f7;
$boxShadowHuge: 0px 0 20px 5px hsla(0, 0%, 82%, 0.38);

/* ---------------------------
	BOOTSTRAP GUTTER
------------------------------------------------------------------------------------------------------- */

$bootstrapGutter: 8px;
$gray200: #eef0f2;
$gray400: #6a737d;
$gray500: #24292e;

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #2A2A2A;
  --toastify-color-info: #D72D5A;
  --toastify-color-success: #D72D5A;
  --toastify-color-warning: #D72D5A;
  --toastify-color-error: #D72D5A;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
    to right,
    #05944F,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}
